import logo from './logo.svg';
import React from "react";
import './App.css';
import { ToastContainer } from 'react-toastify';

import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

const WebHomeLayout = lazy(() => import("./views/routes/index"))


function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Indo American School
    //     </a>
    //   </header>
    // </div>

    <div className="App">
      <ToastContainer />
      <Router>
        {/* <BottomNavBar/> */}
        {/* <ScrollToTop /> */}
        <Switch>
          <Suspense fallback={<></>}>
            <WebHomeLayout />
          </Suspense>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
